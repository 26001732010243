$max-mobile-width: 1023;
$min-web-width: 1024;

$top-panel-color: rgb(35, 44, 50);
$gray-color: rgb(101, 110, 114);
$placeholder-color: rgb(101, 110, 114); // used in ng-zorro\theme.less -> @input-placeholder-color

$blue-tint-color: rgb(199, 220, 237); // from AV Brand Book: Blue Color and 25% tint

// below gray colours were changed to one consistent colour by Accessability Recommendations
// variables were left unchanged to be easy reverted if needed
$dark-gray-color: $gray-color;
$simple-gray-color: $gray-color;
$medium-gray-color: $gray-color;
$darker-gray-color: $medium-gray-color;
$dark-gray-text-color: $dark-gray-color;

$darkest-gray-color: rgb(77, 86, 89);
$darker-gray-text-color: $darker-gray-color;
$darkest-gray-text-color: $darkest-gray-color;
$disabled-text-color: rgba(0, 0, 0, 0.25);

$dark-color: rgb(35, 44, 50);
$blue-color: rgb(0, 121, 168);
$old-darker-gray-color: rgb(114, 127, 131);
$dark-medium-gray-color: rgb(181, 187, 194);
$old-medium-gray-color: rgb(182, 194, 196);
$light-medium-gray-color: rgb(192, 192, 192);
$light-gray-color: rgb(245, 247, 247);
$white-color: rgba(255, 255, 255, 1);
$white-transparent-color: rgba(255, 255, 255, 0);
$white-gray-color: rgb(245, 245, 245);

$black-color: rgb(0, 0, 0);
$red-color: rgb(209, 53, 35);
$orange-color: rgba(231, 123, 72);
$green-color: rgba(18, 193, 129);

$dark-text-color: $dark-color;
$gray-text-color: $gray-color;
$white-text-color: $white-color;
$blue-text-color: $blue-color;

$middle-light-gray-color: rgb(218, 224, 225);
$gray-background: $middle-light-gray-color;
$light-gray-background: rgb(237, 240, 240);

$border-color: rgb(236, 239, 240);
$gray-border-color: rgb(234, 237, 237);

$extra-translation-background: rgb(248, 250, 157);
$missing-translation-background: rgb(255, 204, 204);

$skew: -23deg;

$dealer-header-height: 84px;
$footer-height: 105px;

$success-color: rgb(22, 172, 119);
$warning-color: $orange-color;
$error-color: $red-color;
$mistake-color: $gray-background;
$status-reseller-promo-color: rgb(255, 125, 0);

$valuation-status-bg: rgb(0, 133, 86);
$stock-status-bg: rgb(158, 96, 154);
$reserved-status-bg: rgb(158, 96, 154);

$overlay-bg-color: rgb(0, 143, 201, 0.75);
$dropdown-color: rgb(0, 143, 201);
$dropdown-shadow: rgb(10, 93, 126);

$modal-title-bottom-margin: 20px;
$modal-title-right-margin: 25px;
$modal-background-color: rgba(5, 15, 22, 0.75);

$input-border: 1px solid $gray-border-color;
$input-border-error: 1px solid $red-color;
$input-box-shadow: inset -1px 3px 1px -2px rgb(224, 227, 228);
$number-input-background: $light-gray-color;
$input-background: $light-gray-color;
$old-list-text-color: rgb(114, 127, 131);
$list-text-color: $medium-gray-color;

$selected-border-color: rgb(35, 44, 50);

$light-green-color: rgb(112, 188, 132);

$very-light-gray-shadow-color: rgba(35, 44, 50, 0.1);
$light-gray-shadow-color: rgba(35, 44, 50, 0.15);
$light-gray-shadow-menu-color: rgba(35, 44, 50, 0.2);
$shadow-color: rgba(35, 44, 50, 0.25);

$submenu-background: rgb(59, 72, 80);
$submenu-border: rgb(94, 100, 103);

$simple-red-color: rgb(255, 0, 0);
$transparent-blue: rgba($blue-color, 0.35);
$transparent-light-gray-color: rgba(245, 247, 247, 0.4);
$reseller-price-color: rgb(227, 106, 68);
$news-title-color: rgb(67, 84, 90);
$filter-blue-color: rgb(0, 144, 215);
$list-divider-color: rgba(240, 240, 240, 1);
$select-blue-color: $blue-tint-color;

$no-focus-border-transparent-color: transparent;
$border-focus-color: $blue-color;
$border-focus-blue-shadow: 0 0 0 2px $border-focus-color;
$border-focus-blue-inset-shadow: inset 0 0 0 2px $border-focus-color;
$border-focus-gray-inset-shadow: inset 0 0 0 2px $dark-medium-gray-color;
$border-hover-color: $blue-color;
$focus-glow: 0 0 0 2px rgba(0, 121, 168, 0.2);

$mobile-menu-mask-color: rgba(35, 44, 50, 0.85);

// CO2 Emission Classes colours:
$class-ABC: rgb(21, 124, 17);
$class-ABC-label: rgb(209, 255, 200);
$class-DE: rgb(146, 159, 88);
$class-DE-label: rgb(232, 255, 213);
$class-FG: rgb(224, 189, 55);
$class-FG-label: rgb(255, 236, 219);
$class-HI: rgb(223, 159, 50);
$class-HI-label: rgb(251, 229, 219);
$class-JK: rgb(251, 122, 1);
$class-JK-label: rgb(255, 234, 220);
$class-LM: rgb(255, 5, 44);
$class-LM-label: rgb(255, 230, 229);

$ag-grid-header-color: $white-text-color;

$admin-table-top-space: 201px;
$admin-table-header-height: 38px;

$sticky-z-index: 10;
$re-captcha-z-index: 11;

// UI-kit reset button:
$reset-button-size: 34px;

// vpfa-admin-condition-adjustment-price-item
$admin-condition-adjustment-price-item-width: 200px;
$admin-condition-adjustment-price-items-gap: 20px;

// clone of @shadow-2 from `libs\shared\styles\src\lib\ng-zorro\theme.less`
$dropdown-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
$dropdown-border-radius: 2px;

%blue-thick-arrow {
  color: $blue-color;
  font-weight: 900;
  font-size: 18px;
}

// Use for arrows on black background (VP-14585)
%white-thick-arrow {
  color: $white-color;
  font-weight: 900;
  font-size: 18px;
}

// Use for disabled arrows on black background
%gray-thick-arrow {
  color: $dark-gray-color;
  font-weight: 900;
  font-size: 18px;
}

// from ng-zorro theme.less
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);

$newVehicleBadgeColor: rgb(255, 127, 39);
