@import './variables';
@import './alieron.mixins';
@import './mobile.utils';

.p20px {
  padding: 20px;
}

nz-select:not(.ant-select-disabled) {
  cursor: pointer !important;
  .ant-select-selector {
    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
  }
}

// This is our main scrollable container. If you change it to be something else eg. <body> then, you have to fix
// MobileInfiniteListComponent, because it expect .app-container to have main scrollbar for the app
.app-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.section-name {
  text-transform: uppercase;
}

.primary-btn {
  background-color: $blue-color;
  color: $white-color;
}

.app-theme-red {
  color: $red-color;
}

.ant-popover-content {
  font-size: 11px;
  text-transform: uppercase;
}

.display-block {
  display: block;
}

.modal-confirm-popover {
  .ant-popover-inner-content {
    padding: 20px;
    .anticon {
      display: none;
    }
    .ant-popover-message-title {
      font-size: 16px;
      padding-left: 0;
      text-transform: none;
    }

    .ant-popover-buttons {
      text-align: center;
      margin-top: 10px;
      & button {
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 10px;
        line-height: 22px;
        &.ant-btn-default {
          color: $blue-text-color;
        }
      }
    }
  }
}

@media print {
  // print whole page, not just visible content of app-container
  .app-container {
    width: auto !important;
    height: auto !important;
    overflow: unset !important;
  }
  * {
    font-size: 10px !important;
  }
  .price-value {
    font-size: 30px !important;
  }
  .no-print {
    display: none !important;
  }
  .grecaptcha-logo {
    display: none !important;
  }
  .grecaptcha-badge {
    display: none !important;
  }
  @page {
    margin: 0.5cm;
  }
}

.ant-form-item-control {
  line-height: 34px !important;
}

.text-center {
  text-align: center;
}

[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

.no-border {
  border: none !important;
}

.text-right {
  text-align: right;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 1;
}

.text-align-right {
  text-align: right;
}

.ant-form-item-control-input {
  min-height: 34px;
}

button:focus {
  outline: none;
}

.ant-btn.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
  line-height: 24px;
}

// Remove unnecessary border animation on nz button
[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.ag-header-cell-text {
  font-size: 11px;
  color: $ag-grid-header-color;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-hor-scroll {
  @include media-mobile-query {
    overflow-x: hidden;
  }
}

.sticky-btn {
  @extend %base-sticky-bottom-button;
}

// CIVIC Cookies additional styling

#ccc-icon {
  fill: $black-color !important;
}

#ccc-info {
  display: none;
}

@media (max-width: 575px) {
  #ccc #ccc-icon {
    height: 36px !important;
    width: 36px !important;
  }
}

.grecaptcha-badge {
  z-index: $re-captcha-z-index;
}

.ant-select-selection-placeholder {
  opacity: 1;
}

nz-year-picker.ant-picker {
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border: 1px solid $no-focus-border-transparent-color;
  &.ant-picker-focused {
    &:focus-within {
      background-color: $white-color;
      border-color: $border-focus-color;
    }
  }
}

:not(.ant-input-group-addon) > nz-select.ant-select.ant-select-focused {
  nz-select-top-control.ant-select-selector {
    border: 1px solid $border-focus-color !important;
    background-color: $white-color !important;
  }
}

// nz "no data" colours to be same as in vpfa-empty
nz-empty {
  .ant-empty-image {
    color: $gray-color;
  }

  .ant-empty-description {
    color: $gray-color;
    @include alieron('regular');
  }
}

// min-width:0 - to allow child components to use flex with white-space: nowrap to work as intended
// https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
.min-width {
  min-width: 0;
}

.ant-input-affix-wrapper-disabled {
  .ant-input-suffix {
    color: $disabled-text-color !important;
  }
  .input-hint-mode {
    color: $disabled-text-color !important;
  }
}

.hover-underline {
  transition: all 0.3s ease-in-out;
  text-decoration-color: transparent;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $blue-color;
  }
}

.ssm-input {
  width: 100%;
  padding: 0 4px 0 4px;
  margin-top: 0px;
  border: 1px solid $gray-background;
  border-radius: 2px;
  background-color: $gray-background;
  box-shadow: inset 0px 0px 1px 0px $shadow-color;
  height: 21px;
  display: block;
  color: $gray-color;
  transition: all 0.3s $ease-in-out;

  &:hover {
    border: 1px solid $border-focus-color;
  }

  &.has-focus {
    background: white;
    border: 1px solid $border-focus-color;
    box-shadow: $focus-glow;
  }
}
