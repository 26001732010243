$font-weights: (
  'thin': 100,
  'ultralight': 200,
  'light': 300,
  'regular': 400,
  'semibold': 600,
  'bold': 700,
  'black': 800,
  'heavy': 900
);

@mixin alieron($type: 'regular', $italic: false) {
  $type: to-lower-case($type);
  $font-weight: map-get($font-weights, $type);
  @if $font-weight == null {
    @error "Alieron type #{$type} is unknown.";
  }

  font-family: aileron, sans-serif;
  font-weight: $font-weight;
  @if $italic {
    font-style: italic;
  } @else {
    font-style: normal;
  }
}
