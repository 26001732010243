@import 'node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.css';
@import 'node_modules/text-security/text-security.css';

@import 'lib/ag-grid/ag-theme-ssm';

@import 'node_modules/three-dots/dist/three-dots.css';

@import 'lib/restart';
@import 'lib/global';
@import 'lib/flex/flex.scss';

// prevent `i` info icon hiding on hover
.suffix-info {
  z-index: 10;
}

#cdk-overlay-0 {
  // puts notifications modal on top of others
  z-index: auto;
}

// make arrow white instead of black, theme.less does not have this config option
.ant-tooltip-arrow-content::before {
  background: $white-color;
}

.ant-dropdown-menu-item a {
  color: $darkest-gray-color;
}

nz-notification-container {
  width: 100vw;
  .ant-notification {
    display: inline-block;
    margin-left: 15vw;
    margin-right: 15vw;
    width: 70vw;
    .ant-notification-notice {
      &.error {
        background-color: $error-color;
      }
      &.success {
        background-color: $success-color;
      }
      &.warning {
        background-color: $warning-color;
      }

      &.info {
        background-color: $blue-text-color;
      }

      &.no-description {
        .ant-notification-notice-description {
          display: none;
        }
      }

      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
    }
    .ant-notification-notice-icon {
      display: none;
    }

    .ant-notification-notice-content {
      padding-top: 2px;
    }

    .ant-notification-notice-content,
    .ant-notification-notice-message,
    .anticon,
    .ant-notification-notice-description {
      color: $white-color;
      margin-left: 0;
    }
  }
}
