@import './variables';

$mobile-design-scale: 720/375;

// convert mobile design unit to real design unit
@function md2real($desPx) {
  @return $desPx / $mobile-design-scale;
}

%base-sticky-bottom-button {
  padding-top: 30px;
  padding-bottom: 10px;
  background: linear-gradient(0deg, $white-color 0%, $white-color 80%, rgba(255, 255, 255, 0) 100%);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0 !important;
  width: 100%;
  z-index: $sticky-z-index;
  display: block;
}

@mixin media-desktop-query {
  @media screen and (min-width: #{$min-web-width}px) {
    @content;
  }

  @media print {
    @content;
  }
}

@mixin media-mobile-query {
  @media only screen and (max-width: #{$max-mobile-width}px) {
    @content;
  }
}
