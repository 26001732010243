@import './variables.scss';
@import './mobile.utils';

// replaces fxLayout.lt-mv="column"
.flex-mv-column {
  @include media-mobile-query {
    display: flex;
    flex-direction: column;
  }
}

// replaces fxLayout.gt-mv="column"
.flex-web-column {
  @include media-desktop-query {
    display: flex;
    flex-direction: column;
  }
}

// replaces fxLayout.lt-mv="row"
.flex-mv-row {
  @include media-mobile-query {
    display: flex;
    flex-direction: row;
  }
}

// replaces fxLayout.gt-mv="row"
.flex-web-row {
  @include media-desktop-query {
    display: flex;
    flex-direction: row;
  }
}

// replaces FlexLayout's fxLayoutGap="??px"
@for $i from 1 through 100 {
  .gap-#{$i}px {
    gap: #{$i}px;
  }
}

// replaces FlexLayout's fxLayoutGap.lt-mv="??px"
@for $i from 1 through 100 {
  @include media-mobile-query {
    .gap-mobile-#{$i} {
      gap: #{$i}px;
    }
  }
}

// replaces FlexLayout's fxLayoutGap.gt-mv="??px"
@for $i from 1 through 100 {
  @include media-desktop-query {
    .gap-desktop-#{$i} {
      gap: #{$i}px;
    }
  }
}

// Flex Width in Percent. Replaces FlexLayout's fxFlex="??%"
@for $i from 1 through 100 {
  .flex-width-#{$i} {
    max-width: percentage($i / 100);
    flex: 1 1 100%;
  }
}

// Flex Width in Pixels. Replaces FlexLayout's fxFlex="??px"
@for $i from 1 through 100 {
  .flex-width-#{$i * 10}px {
    max-width: #{$i * 10}px;
    min-width: #{$i * 10}px;
    flex: 1 1 100%;
  }
}

// Flex Width in Percent for *Desktop*. Replaces FlexLayout's fxFlex.gt-mv="??%"
@for $i from 1 through 100 {
  @include media-desktop-query {
    .flex-web-width-#{$i} {
      max-width: percentage($i / 100);
      flex: 1 1 100%;
    }
  }
}

// Flex Width in Pixels for *Desktop*. Replaces FlexLayout's fxFlex.gt-mv="??px"
@for $i from 1 through 100 {
  @include media-desktop-query {
    .flex-web-width-#{$i * 10}px {
      max-width: #{$i * 10}px;
      flex: 1 1 100%;
    }
  }
}

// Flex Width in Percent for *Mobile*. Replaces FlexLayout's fxFlex.lt-mv="??%"
@for $i from 1 through 100 {
  @include media-mobile-query {
    .flex-mobile-width-#{$i} {
      max-width: percentage($i / 100);
      flex: 1 1 100%;
    }
  }
}

// Flex Width in Pixels for *Mobile*. Replaces FlexLayout's fxFlex.lt-mv="??px"
@for $i from 1 through 100 {
  @include media-mobile-query {
    .flex-mobile-width-#{$i * 10}px {
      max-width: #{$i * 10}px;
      flex: 1 1 100%;
    }
  }
}

// Replaces FlexLayout's fxFlex="auto"
.flex-auto {
  flex: 1 1 auto;
}

.flex-align-items-center {
  align-items: center
}