@import 'variables';
@import 'node_modules/ag-grid-community/styles/ag-grid.css';
@import 'node_modules/ag-grid-community/styles/agGridMaterialFont.css';

.ag-theme-ssm {
  --ag-background-color: #{$white-color};
  --ag-header-background-color: #{$dark-color};
  --ag-font-family: aileron;
  --ag-font-size: 14px;
  --ag-disabled-foreground-color: rgba(black, 0.5);
  --ag-foreground-color: rgba(black, 1);
  --ag-secondary-foreground-color: rgba(#333);
  --ag-border-color: transparent;
  --ag-header-foreground-color: #{$gray-text-color};
  --ag-icon-font-family: agGridMaterial;
  --ag-odd-row-background-color: #{$white-color};
  --ag-header-cell-moving-background-color: #{$darkest-gray-color};

  .ag-cell-label-container {
    padding: 0;
  }

  .ag-icon {
    font-weight: 900;
  }
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.ag-center-cols-container {
  min-width: 100%;
}

.ag-root {
  border: none !important;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.text-gray {
  color: $gray-text-color;
}

.ag-row {
  border-bottom: solid 1px $gray-background !important;
  border-left: 1px solid $gray-background !important;
  border-right: 1px solid $gray-background !important;
}

.ag-cell {
  display: flex;
  align-items: center;
  line-height: unset !important;

  & .ag-cell-wrapper {
    width: 100%;
  }
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-row-hover {
  background-color: $light-gray-color;
  cursor: pointer;
}

.ag-row-selected {
  &.wizard-mode {
    background-color: $transparent-blue !important;
  }
}

.ag-header-row:first-child > .ag-header-cell {
  padding-top: 9px;
  padding-bottom: 8px;
}

.ag-header-row:nth-child(2) > .ag-header-cell {
  padding-top: 1px;
  padding-bottom: 11px;
}

.ag-header-row {
  .ag-header-cell-resize:after {
    content: '';
    position: absolute;
    z-index: 1;
    display: block;
    left: calc(50% - 1px);
    width: 2px;
    height: 30%;
    top: calc(50% - 15%);
    background-color: $dark-gray-color;
  }
}

.ag-header-cell {
  border: none !important;

  & .ag-floating-filter-full-body input {
    width: 100%;
  }

  & .ag-header-cell-text {
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: middle;
  }

  & .ag-header-cell-sorted-desc {
    color: $white-color;
  }

  & .ag-header-cell-sorted-asc {
    color: $white-color;
  }

  & .ag-floating-filter-input {
    transition:
      background-color 0.2s ease,
      border-color 0.2s ease;
    margin-top: 0px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 1px 0px $shadow-color;
    height: 21px;
    padding: 1px;

    &:not(:focus-within) {
      border: none !important;
      background-color: $gray-background;
    }

    &:focus-within {
      background-color: $white-color;
      border: 1px solid $border-focus-color;
    }
  }
}
